import Svg from "./Svg.js";
import React, { Component } from "react";

export default class Placeholder extends Component {
	render() {
		return (
			<div className="single-taak ds-crm-laden-placeholder">
				<div className="status">
					<Svg />
				</div>
				<div className="taak">
					<Svg />
				</div>
				<div className="datum">
					<Svg />
				</div>
				<div className="gekoppelde-gebruiker">
					<Svg />
				</div>
			</div>
		);
	}
}