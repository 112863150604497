import React from 'react';
import { getKaart } from '../utils/query.js';
import $ from 'jquery';
import renderHTML from '../utils/renderHTML';

var timestampLastUpdate;

export default class BordKaartenSingleKaart extends React.Component {
	state = {
		template: this.props.template,
		post: this.props.post,
		debug: false,
	}

	componentDidMount(){
		this.handleChange = this.handleChange.bind(this);
		this.changeListener();
	}

	componentDidUpdate(){
		// NOTE: lijkt niet nodig? Niet in voorbeeld op https://reactjs.org/docs/integrating-with-other-libraries.html
		// this.handleChange = this.handleChange.bind(this);
		this.changeListener();

		if (this.state.post)
			this.renderComplete();
	}

	componentWillUnmount(){
		if (this.state.post.id ){
			if (this.state.debug)
				console.log('componentWillUnmount - $.off(ds-crm-react-update)', $(`#kaart-${this.state.post.id}`))

			// Event listener voor update triggers loskoppelen
			$(`#kaart-${this.state.post.id}`).off('ds-crm-react-update', this.handleChange)
		}
	}

	renderComplete(){
		if (this.state.debug)
			console.log('single item - renderComplete() - trigger ds-crm-web-component-geladen');

		// Trigger geven dat web component is geladen voor verdere initialisatie van bijv. custom selects
		$(document)[0].dispatchEvent(
			new Event('ds-crm-web-component-geladen', {
				bubbles: true,
				composed: true,
			}),
		);
	}

	changeListener(event) {
		if (this.state.debug)
			console.log('ds-crm-react-update - changelistener - $.on(ds-crm-react-update)', $(`#kaart-${this.state.post.id}`), event)

		// Event listener voor update triggers registreren
		$(`#kaart-${this.state.post.id}`).on('ds-crm-react-update', this.handleChange)
	}

	handleChange(event) {
		if (event.target.matches(`#kaart-${this.state.post.id}`) && timestampLastUpdate !== event.timeStamp){
			if (this.state.debug)
				console.log('single item - handleChange - update ophalen voor ID: ' + this.state.post.id, event)

			// Nieuwe data ophalen na change
			getKaart( this.state.post.id )
				.then(json => {
					this.setState({ post: json });
				});

			timestampLastUpdate = event.timeStamp;
		}
	}

	render(){
		// In het sporadische geval dat de items sneller zijn geladen dan het template wachten op het template
		if (!this.state.template) return;

		var id = (this.state.post.id ? this.state.post.id : '');
		var id_encrypted = (this.state.post.id_encrypted ? this.state.post.id_encrypted : '');
		var titel = (this.state.post.title.rendered ? this.state.post.title.rendered : '');
		var href = (this.state.post.ds_crm_permalink ? this.state.post.ds_crm_permalink : '');
		var edit_post_link = (this.state.post.edit_post_link ? this.state.post.edit_post_link : '');
		var dropdown_menu = (this.state.post.dropdown_menu ? this.state.post.dropdown_menu : '');

		var metadata = this.state.post.metadata;
		var gekoppelde_gebruikers = (metadata && metadata.kaart_gekoppelde_gebruikers ? metadata.kaart_gekoppelde_gebruikers : '');
		var status = (metadata && metadata.status ? metadata.status : '');
		var startdatum = (metadata && metadata.startdatum ? metadata.startdatum : '');
		var vervaldatum = (metadata && metadata.vervaldatum ? metadata.vervaldatum : '');
		var herhalen = (metadata && metadata.herhalen ? metadata.herhalen : '');
		var prioriteit = (metadata && metadata.prioriteit ? metadata.prioriteit : '');
		var afhankelijkheden = (metadata && metadata.kaart_afhankelijkheden ? metadata.kaart_afhankelijkheden : '');
		var telt_voor_maandelijks_quotum = (metadata && metadata['telt-voor-maandelijks-quotum'] ? metadata['telt-voor-maandelijks-quotum'] : '');
		var klant_mag_bewerken = (metadata && metadata['klant-mag-bewerken'] ? metadata['klant-mag-bewerken'] : '');

		var template = (status !== undefined && status === 'voltooid' ? this.state.template.voltooid : this.state.template.open );

		return renderHTML(
			template
				.replaceAll('${id}', id) // eslint-disable-line
				.replaceAll('${id-encrypted}', id_encrypted) // eslint-disable-line
				.replaceAll('${titel}', titel) // eslint-disable-line
				.replaceAll('${href}', href) // eslint-disable-line
				.replaceAll('${gekoppelde-gebruikers-json}', gekoppelde_gebruikers) // eslint-disable-line
				.replaceAll('${status}', status) // eslint-disable-line
				.replaceAll('${startdatum}', startdatum) // eslint-disable-line
				.replaceAll('${vervaldatum}', vervaldatum) // eslint-disable-line
				.replaceAll('${herhalen}', herhalen) // eslint-disable-line
				.replaceAll('${prioriteit}', prioriteit) // eslint-disable-line
				.replaceAll('${afhankelijkheden}', afhankelijkheden) // eslint-disable-line
				.replaceAll('${telt-voor-maandelijks-quotum}', telt_voor_maandelijks_quotum) // eslint-disable-line
				.replaceAll('${klant-mag-bewerken}', klant_mag_bewerken) // eslint-disable-line
				.replaceAll('${edit-post-link}', edit_post_link) // eslint-disable-line
				.replaceAll('${dropdown-menu}', dropdown_menu) // eslint-disable-line
		)
	}
}