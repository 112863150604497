import React from 'react';
import ReactDOM from 'react-dom/client';
import BordenTabelSingleBord from './BordenTabelSingleBord.js';
import BordenPlaceholder from './placeholders/Borden.js';
import { getTemplate, getBorden } from '../utils/query.js';
import renderHTML from '../utils/renderHTML';
import $ from 'jquery';

var wrapperSelector = '.borden.tabel';
var timestampLastUpdate;

// TEST: click voor update triggers opvolgen in development omgeving
if (process.env.NODE_ENV === "development") {
	$(function(){
		$(document).on('click', '.bord-refresh', function(){
			console.log('click op bord-refresh, simuleer refresh voor bord ' + $(this).data('id'))
			$('#bord-' + $(this).data('id')).trigger('ds-crm-react-update')
		})
		$(document).on('click', '#refresh-alle-borden', function(){
			console.log('click op refresh-alles, alles verversen')
			$(wrapperSelector).trigger('ds-crm-react-full-update');
		})
	})
}

export default class BordenTabel extends React.Component {
	state = {
		borden: [],
		template: '',
		loading: true,
		debug: false,
	}

	componentDidMount(){
		// Template eenmalig ophalen
		getTemplate( 'borden-tabel-single-bord' )
			.then(json => {
				this.setState({ template: json });
			});

		this.restRequest();

		this.fullUpdate = this.fullUpdate.bind(this);
		this.updateListener();

		if (this.state.borden.length > 0)
			this.renderComplete();
	}

	componentDidUpdate(){
		// NOTE: lijkt niet nodig? Niet in voorbeeld op https://reactjs.org/docs/integrating-with-other-libraries.html
		// this.fullUpdate = this.fullUpdate.bind(this);
		this.updateListener();

		if (this.state.borden.length > 0)
			this.renderComplete();
	}

	componentWillUnmount(){
		if (this.state.debug)
			console.log('componentWillUnmount - $.off(ds-crm-react-full-update)', $(wrapperSelector))

		// Event listener voor update triggers loskoppelen
		$(wrapperSelector).off('ds-crm-react-full-update', this.fullUpdate)
	}

	renderComplete(){
		if (this.state.debug)
			console.log('renderComplete - trigger ds-crm-web-component-geladen');

		// Trigger geven dat web component is geladen voor verdere initialisatie van bijv. custom selects
		$(document)[0].dispatchEvent(
			new Event('ds-crm-web-component-geladen', {
				bubbles: true,
				composed: true,
			}),
		);
	}

	updateListener(event) {
		if (this.state.debug)
			console.log('ds-crm-react-full-update - updatelistener - $.on(ds-crm-react-full-update)', $(wrapperSelector), event)

		// Event listener voor update triggers registreren
		$(wrapperSelector).on('ds-crm-react-full-update', this.fullUpdate)
	}

	fullUpdate(event){
		if (event.target.matches(wrapperSelector) && timestampLastUpdate !== event.timeStamp){
			if (this.state.debug)
				console.log('fullUpdate')

			this.restRequest();

			timestampLastUpdate = event.timeStamp;
		}
	}

	restRequest(){
		var status = (this.props && this.props['data-status'] ? this.props['data-status'] : false)

		// Borden ophalen
		getBorden({ item_status: status })
			.then(json => {
				this.setState({ borden: json });
			})
			.then(json => {
				// this.setState({ loading: false })
				setTimeout(() => this.setState({ loading: false }), window.loadingTimeout);
			});
	}

	render(){
		if (process.env.NODE_ENV === "development") {
			return (
				<React.Fragment>
					<button id="refresh-alle-borden">REFRESH ALLES</button>
					<button className="bord-refresh"
						data-id={this.state.borden.length > 0 ? this.state.borden[0].id : 'niet bekend'}
					>REFRESH 1E BORD IN LIJST</button>
					<button className="bord-refresh"
						data-id={this.state.borden.length > 1 ? this.state.borden[1].id : 'niet bekend'}
					>REFRESH 2E BORD IN LIJST</button>

					<h1>Borden:</h1>

					<div className="borden tabel">
						{this.state.template && renderHTML(this.state.template['tabel-header'])}
						{this.state.loading && <BordenPlaceholder/>}
						{!this.state.loading && this.state.borden.map((post, index) =>
							// NOTE: post.modified in key zodat na update van een bord het element opnieuw wordt gerendered
							<BordenTabelSingleBord key={`BordenTabelSingleBord-${post.id}-${Date.now()}`}
								post={post}
								template={this.state.template}
							/>
						)}
					</div>
				</React.Fragment>
			)
		} else {
			return (
				<div className="borden tabel">
					{this.state.template && renderHTML(this.state.template['tabel-header'])}
					{this.state.loading && <BordenPlaceholder/>}
					{!this.state.loading && this.state.borden.map((post, index) =>
						// NOTE: post.modified in key zodat na update van een bord het element opnieuw wordt gerendered
						<BordenTabelSingleBord key={`BordenTabelSingleBord-${post.id}-${Date.now()}`}
							post={post}
							template={this.state.template}
						/>
					)}
				</div>
			)
		}
	}
}

class BordenTabelComponent extends HTMLElement {
	// constructor(){
	// 	super();

	// 	// Do something more
	// }

	connectedCallback(){
		const attNames = this.getAttributeNames();
		var props = [];
		attNames.map((name) => {
			props[name] = this.getAttribute(name);
			return props;
		});

		// // Create a ShadowDOM
		// const root = this; //.attachShadow({ mode: 'open' });

		// // Create a mount element
		// const mountPoint = document.createElement('div');

		// // Place mount element
		// root.appendChild(mountPoint);

		// Render output inside mount element
		const rootMountPoint = ReactDOM.createRoot(this);
		rootMountPoint.render(
			<React.StrictMode>
				<BordenTabel {...props} />
			</React.StrictMode>
		)
	}
}

customElements.define('borden-tabel', BordenTabelComponent);