import React from 'react';
import { getMeldingen } from '../utils/query.js';
import renderHTML from '../utils/renderHTML';
import $ from 'jquery';

var timestampLastUpdate;

export default class MeldingenSingleMelding extends React.Component {
	state = {
		template: this.props.template,
		post: this.props.post,
		debug: false,
	}

	componentDidMount(){
		this.handleChange = this.handleChange.bind(this);
		this.changeListener()
	}

	componentDidUpdate(){
		// NOTE: lijkt niet nodig? Niet in voorbeeld op https://reactjs.org/docs/integrating-with-other-libraries.html
		// this.handleChange = this.handleChange.bind(this);
		this.changeListener()

		if (this.state.post)
			this.renderComplete();
	}

	componentWillUnmount(){
		if (this.state.debug)
			console.log('componentWillUnmount - $.off(ds-crm-react-update)', $(`#melding-${this.state.post.id}`))

		// Event listener voor update triggers loskoppelen
		$(`#melding-${this.state.post.ID}`).off('ds-crm-react-update', this.handleChange)
	}

	renderComplete(){
		if (this.state.debug)
			console.log('single item - renderComplete() - trigger ds-crm-web-component-geladen');

		// Trigger geven dat web component is geladen voor verdere initialisatie van bijv. custom selects
		$(document)[0].dispatchEvent(
			new Event('ds-crm-web-component-geladen', {
				bubbles: true,
				composed: true,
			}),
		);
	}

	changeListener(event) {
		if (this.state.debug)
			console.log('ds-crm-react-update - changelistener - $.on(ds-crm-react-update)', $(`#melding-${this.state.post.id}`), event)

		// Event listener voor update triggers registreren
		$(`#melding-${this.state.post.ID}`).on('ds-crm-react-update', this.handleChange)
	}

	handleChange(event) {
		if (event.target.matches(`#melding-${this.state.post.ID}`) && timestampLastUpdate !== event.timeStamp){
			if (this.state.debug)
				console.log('single item - handleChange - update ophalen voor ID: ' + this.state.post.ID, event)

			// Nieuwe data ophalen na change
			getMeldingen( this.state.post.ID )
				.then(json => {
					this.setState({ post: json.posts[0] });
				});

			timestampLastUpdate = event.timeStamp;
		}
	}

	render(){
		// In het sporadische geval dat de items sneller zijn geladen dan het template wachten op het template
		if (!this.state.template) return;

		var id = (this.state.post.ID ? this.state.post.ID : '')
		var id_encrypted = (this.state.post.id_encrypted ? this.state.post.id_encrypted : '')
		var melding_weergave = (this.state.post.melding_weergave ? this.state.post.melding_weergave : '')

		var metadata = this.state.post.metadata;
		var gezien = (metadata && metadata['melding-gezien'] ? metadata['melding-gezien'] : '')

		var template = (gezien ? this.state.template['melding-gezien'] : this.state.template['melding-nieuw'])

		return renderHTML(
			template
				.replaceAll('${id}', id) // eslint-disable-line
				.replaceAll('${id-encrypted}', id_encrypted) // eslint-disable-line
				.replaceAll('${melding-weergave}', melding_weergave) // eslint-disable-line
		)
	}
}