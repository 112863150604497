import API from '../utils/api.js';

// TODO: user ID anders ophalen of current user check via WP-Nonce in axios header
function getUserID(){
	if(process.env.NODE_ENV === "development"){
		return 1
	} else {
		return variabelen.userID // eslint-disable-line
	}
}

export async function getTemplate( template ){
	return await API.get(
		`wp-json/ds-crm/v1/get-template/${template}`,
		{ params: {
			'user_id': getUserID(),
		} }
	).then(res => {
		return res.data;
	})
}

export async function getMeldingen( ID ){
	return await API.get(
		'/wp-json/ds-crm/v1/ds-crm-get-meldingen',
		{ params: {
			'id': ID,
			'user_id': getUserID(),
			'custom_per_page' : -1,
		} }
	).then(res => {
		return res.data;
	})
}

export async function getBorden(props){
	var item_status = (props.item_status ? props.item_status : '');

	return await API.get(
		'/wp-json/wp/v2/ds-crm-bord',
		{ params: {
			'user_id': getUserID(),
			'ds_crm_permalink': 1,
			'dropdown_menu': 'bord-dropdown-menu',
			'metadata' : 1,
			'item_status': item_status,
			'custom_per_page' : -1,
			'orderby' : 'title',
			'order' : 'asc',
		} }
	).then(res => {
		return res.data;
	})
}

export async function getBord( ID ){
	return await API.get(
		`/wp-json/wp/v2/ds-crm-bord/${ID}`,
		{ params: {
			'user_id': getUserID(),
			'ds_crm_permalink': 1,
			'dropdown_menu': 'bord-dropdown-menu',
			'metadata' : 1,
		} }
	).then(res => {
		return res.data;
	})
}

export async function getKaarten( parent ){
	return await API.get(
		'/wp-json/wp/v2/ds-crm-kaart',
		{ params: {
			'user_id': getUserID(),
			'parent' : ( parent ?  parent : '' ),
			'ds_crm_permalink': 1,
			'dropdown_menu': 'kaart-dropdown-menu',
			'metadata' : 1,
			'custom_per_page' : -1,
			'order-by' : 'order',
			'order' : 'asc',
		} }
	).then(res => {
		return res.data;
	})
}

export async function getKaart( ID ){
	return await API.get(
		`/wp-json/wp/v2/ds-crm-kaart/${ID}`,
		{ params: {
			'user_id': getUserID(),
			'ds_crm_permalink': 1,
			'dropdown_menu': 'kaart-dropdown-menu',
			'metadata': 1,
		} }
	).then(res => {
		return res.data;
	})

}

export async function getChecklistItems( parent ){
	return await API.get(
		'/wp-json/wp/v2/ds-crm-check-item',
		{ params: {
			'user_id': getUserID(),
			'parent' : ( parent ?  parent : '' ),
			'metadata' : 1,
			'bord_id' : 1,
			'custom_per_page' : -1,
			'order-by' : 'order',
			'order' : 'asc',
		} }
	).then(res => {
		return res.data;
	})
}

export async function getChecklistItem( ID ){
	return await API.get(
		`/wp-json/wp/v2/ds-crm-check-item/${ID}`,
		{ params: {
			'user_id': getUserID(),
			'metadata': 1,
			'bord_id': 1,
		} }
	).then(res => {
		return res.data;
	})
}