import React from 'react';
import ReactDOM from 'react-dom/client';
import SingleChecklistItem from './SingleChecklistItem.js';
import ChecklistPlaceholder from './placeholders/Checklist.js';
import { getTemplate, getChecklistItems } from '../utils/query.js';
import $ from 'jquery';

var timestampLastUpdate;

if (process.env.NODE_ENV === "development") {
	$(function(){
		$(document).on('click', '.taak-refresh', function(){
			console.log('click op checklist-refresh, simuleer refresh voor taak ' + $(this).data('id'))
			$('#taak-' + $(this).data('id')).trigger('ds-crm-react-update')
		})
		$(document).on('click', '#refresh-alle-taken', function(){
			console.log('click op refresh-alles, alles verversen')
			$('#checklist-' + $(this).data('id')).trigger('ds-crm-react-full-update');
		})
	})
}

export default class SingleChecklist extends React.Component {
	state = {
		checkItems: [],
		template: '',
		loading: true,
		debug: false,
	}

	componentDidMount(){
		// Template eenmalig ophalen
		getTemplate( 'checklist-item' )
			.then(json => {
				this.setState({ template: json });
			});

		this.restRequest();

		this.fullUpdate = this.fullUpdate.bind(this);
		this.updateListener();

		if (this.state.checkItems.length > 0)
			this.renderComplete();
	}

	componentDidUpdate(){
		// NOTE: lijkt niet nodig? Niet in voorbeeld op https://reactjs.org/docs/integrating-with-other-libraries.html
		// this.fullUpdate = this.fullUpdate.bind(this);
		this.updateListener();

		if (this.state.checkItems.length > 0)
			this.renderComplete();
	}

	componentWillUnmount(){
		if (this.state.debug)
			console.log('componentWillUnmount - $.off(ds-crm-react-full-update)', $(`#checklist-${this.props.parent}`))

		// Event listener voor update triggers loskoppelen
		$(`#checklist-${this.props.parent}`).off('ds-crm-react-full-update', this.fullUpdate)
	}

	renderComplete(){
		if (this.state.debug)
			console.log('renderComplete - trigger ds-crm-web-component-geladen');

		// Trigger geven dat web component is geladen voor verdere initialisatie van bijv. custom selects
		$(document)[0].dispatchEvent(
			new Event('ds-crm-web-component-geladen', {
				bubbles: true,
				composed: true,
			}),
		);
	}

	updateListener(event) {
		if (this.state.debug)
			console.log('ds-crm-react-full-update - updatelistener - $.on(ds-crm-react-full-update)', $(`#checklist-${this.props.parent}`), event)

		// Event listener voor update triggers registreren
		$(`#checklist-${this.props.parent}`).on('ds-crm-react-full-update', this.fullUpdate)
	}

	fullUpdate(event){
		if (event.target.matches(`#checklist-${this.props.parent}`) && timestampLastUpdate !== event.timeStamp){
			if (this.state.debug)
				console.log('fullUpdate')

			this.restRequest();

			timestampLastUpdate = event.timeStamp;
		}
	}

	restRequest(){
		var parent = this.props.parent;

		// Checklist items ophalen
		getChecklistItems( parent )
			.then(json => {
				this.setState({ checkItems: json });
			})
			.then(json => {
				// this.setState({ loading: false })
				setTimeout(() => this.setState({ loading: false }), window.loadingTimeout);
			});
	}


	render(){
		if (process.env.NODE_ENV === "development") {
			return (
				<React.Fragment>
					<button id="refresh-alle-taken"
						data-id={this.props.parent}
					>REFRESH ALLES</button>
					<button className="taak-refresh"
						data-id={this.state.checkItems.length > 0 ? this.state.checkItems[0].id : 'niet bekend'}
					>REFRESH 1E TAAK IN LIJST</button>
					<button className="taak-refresh"
						data-id={this.state.checkItems.length > 1 ? this.state.checkItems[1].id : 'niet bekend'}
					>REFRESH 2E TAAK IN LIJST</button>

					<h1>Checklist taken:</h1>
					{this.state.loading && <ChecklistPlaceholder/>}
					<section id={`checklist-${this.props.parent}`}>
						{!this.state.loading && this.state.checkItems.map((post, index) =>
							<SingleChecklistItem key={`SingleChecklistItem-${post.id}-${Date.now()}`}
								post={post}
								template={this.state.template}
							/>
						)}
					</section>
				</React.Fragment>
			)
		} else {
			return (
				<React.Fragment>
					{this.state.loading && <ChecklistPlaceholder/>}
					<section id={`checklist-${this.props.parent}`}>
						{!this.state.loading && this.state.checkItems.map((post, index) =>
							<SingleChecklistItem key={`SingleChecklistItem-${post.id}-${Date.now()}`}
								post={post}
								template={this.state.template}
							/>
						)}
					</section>
				</React.Fragment>
			)
		}
	}
}

class SingleChecklistComponent extends HTMLElement {
	// constructor(){
	// 	super();

	// 	// Do something more
	// }

	connectedCallback(){
		const attNames = this.getAttributeNames();
		var props = [];
		attNames.map((name) => {
			props[name] = this.getAttribute(name);
			return props;
		});

		// // Create a ShadowDOM
		// const root = this; //.attachShadow({ mode: 'open' });

		// // Create a mount element
		// const mountPoint = document.createElement('div');

		// // Place mount element
		// root.appendChild(mountPoint);

		// Render output inside mount element
		const rootMountPoint = ReactDOM.createRoot(this);
		rootMountPoint.render(
			<React.StrictMode>
				<SingleChecklist {...props} />
			</React.StrictMode>
		)
	}
}

customElements.define('single-checklist', SingleChecklistComponent);