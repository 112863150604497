import Svg from "./Svg.js";
import React, { Component } from "react";

export default class Placeholder extends Component {
	render(){
		return (
			<div className="single-bord ds-crm-laden-placeholder">
				<div className="status">
					<Svg />
				</div>
				<div className="opdracht">
					<Svg />
					<Svg />
				</div>
				<div className="kaarten">
					<Svg />
				</div>
				<div className="uren">
					<Svg />
				</div>
				<div className="status">
					{/* <Svg /> */}
				</div>
			</div>
		);
	}
}