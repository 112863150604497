import Svg from "./Svg.js";
import React, { Component } from "react";

export default class Placeholder extends Component {
	render(){
		return (
			<div className="single-kaart ds-crm-laden-placeholder">
				<div className="kaart-titel">
					<Svg />
				</div>
				<div className="kaart-meta">
					<Svg className="kaart-meta" />
				</div>
			</div>
		);
	}
}