import React from 'react';
import { getBord } from '../utils/query.js';
import $ from 'jquery';
import renderHTML from '../utils/renderHTML';

var timestampLastUpdate;

export default class BordenTabelSingleBord extends React.Component {
	state = {
		template: this.props.template,
		post: this.props.post,
		debug: false,
	}

	componentDidMount(){
		this.handleChange = this.handleChange.bind(this);
		this.changeListener()
	}

	componentDidUpdate(){
		// NOTE: lijkt niet nodig? Niet in voorbeeld op https://reactjs.org/docs/integrating-with-other-libraries.html
		// this.handleChange = this.handleChange.bind(this);
		this.changeListener()

		if (this.state.post)
			this.renderComplete();
	}

	componentWillUnmount(){
		if (this.state.debug)
			console.log('componentWillUnmount - $.off(ds-crm-react-update)', $(`#bord-${this.state.post.id}`))

		// Event listener voor update triggers loskoppelen
		$(`#bord-${this.state.post.id}`).off('ds-crm-react-update', this.handleChange)
	}

	renderComplete(){
		if (this.state.debug)
			console.log('single item - renderComplete() - trigger ds-crm-web-component-geladen');

		// Trigger geven dat web component is geladen voor verdere initialisatie van bijv. custom selects
		$(document)[0].dispatchEvent(
			new Event('ds-crm-web-component-geladen', {
				bubbles: true,
				composed: true,
			}),
		);
	}

	changeListener(event) {
		if (this.state.debug)
			console.log('ds-crm-react-update - changelistener - $.on(ds-crm-react-update)', $(`#bord-${this.state.post.id}`), event)

		// Event listener voor update triggers registreren
		$(`#bord-${this.state.post.id}`).on('ds-crm-react-update', this.handleChange)
	}

	handleChange(event) {
		if (event.target.matches(`#bord-${this.state.post.id}`) && timestampLastUpdate !== event.timeStamp){
			if (this.state.debug)
				console.log('single item - handleChange - update ophalen voor ID: ' + this.state.post.id, event)

			// Nieuwe data ophalen na change
			getBord( this.state.post.id )
				.then(json => {
					this.setState({ post: json });
				});

			timestampLastUpdate = event.timeStamp;
		}
	}

	render(){
		// In het sporadische geval dat de items sneller zijn geladen dan het template wachten op het template
		if (!this.state.template) return;

		var id = (this.state.post.id ? this.state.post.id : '');
		var id_encrypted = (this.state.post.id_encrypted ? this.state.post.id_encrypted : '');
		var titel = (this.state.post.title.rendered ? this.state.post.title.rendered : '');
		var permalink = (this.state.post.ds_crm_permalink ? this.state.post.ds_crm_permalink : '');
		var dropdown_menu = (this.state.post.dropdown_menu ? this.state.post.dropdown_menu : '');

		var metadata = this.state.post.metadata;
		var status = (metadata && metadata.status ? metadata.status : '');
		var status_html = (metadata && metadata.status_html ? metadata.status_html : '');
		var klant = (metadata && metadata.klant ? metadata.klant : '');
		var team = (metadata && metadata.team ? metadata.team : '');

		var template = (status !== undefined && status === 'voltooid' ? this.state.template.voltooid : this.state.template.open );

		return renderHTML(
			template
				.replaceAll('${id}', id) // eslint-disable-line
				.replaceAll('${id-encrypted}', id_encrypted) // eslint-disable-line
				.replaceAll('${titel}', titel) // eslint-disable-line
				.replaceAll('${permalink}', permalink) // eslint-disable-line
				.replaceAll('${status_html}', status_html) // eslint-disable-line
				.replaceAll('${klant}', klant) // eslint-disable-line
				.replaceAll('${team}', team) // eslint-disable-line
				.replaceAll('${dropdown-menu}', dropdown_menu) // eslint-disable-line
		)
	}
}