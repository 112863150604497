import axios from 'axios';

// TODO: user ID anders ophalen of current user check via WP-Nonce in axios header
function getBaseURL(){
	if(process.env.NODE_ENV === "development"){
		return 'https://projecten.ds-webdesign.nl';
	} else {
		console.warn(variabelen.getSiteUrl, variabelen) // eslint-disable-line
		return variabelen.getSiteUrl; // eslint-disable-line
	}
}

export default axios.create({
	baseURL: getBaseURL(),
	// headers: {
	// 	'content-type': 'application/json',
	// 	'X-WP-Nonce': (typeof variabelen !== 'undefined' ? variabelen.rest.nonce : ''), // eslint-disable-line
	// },
});