import React from 'react';
import ReactDOM from 'react-dom/client';
import MeldingenSingleMelding from './MeldingenSingleMelding.js';
import { getTemplate, getMeldingen } from '../utils/query.js';
import $ from 'jquery';

var wrapperSelector = '#ds-crm-meldingen-dropdown';
var timestampLastUpdate;

// TEST: click voor update triggers opvolgen in development omgeving
if (process.env.NODE_ENV === "development") {
	$(function(){
		$(document).on('click', '.melding-refresh', function(event){
			console.log('click op melding-refresh, simuleer refresh voor melding ' + $(this).data('id'))
			$('#melding-' + $(this).data('id')).trigger('ds-crm-react-update')
		})
		$(document).on('click', '#refresh-alle-meldingen', function(event){
			console.log('click op refresh-alles, alles verversen')
			$(wrapperSelector).trigger('ds-crm-react-full-update');
		})
	})
}

export default class MeldingenDropdown extends React.Component {
	state = {
		meldingen: [],
		template: [],
		debug: false,
	}

	componentDidMount(){
		// Template eenmalig ophalen
		getTemplate( 'melding' )
			.then(json => {
				this.setState({ template: json });
			});

		this.restRequest();

		this.fullUpdate = this.fullUpdate.bind(this);
		this.updateListener();

		if (this.state.meldingen.length > 0)
			this.renderComplete();
	}

	componentDidUpdate(){
		// NOTE: lijkt niet nodig? Niet in voorbeeld op https://reactjs.org/docs/integrating-with-other-libraries.html
		// this.fullUpdate = this.fullUpdate.bind(this);
		this.updateListener();

		if (this.state.meldingen.length > 0)
			this.renderComplete();
	}

	componentWillUnmount(){
		if (this.state.debug)
			console.log('componentWillUnmount - $.off(ds-crm-react-full-update)', $(wrapperSelector))

		// Event listener voor update triggers loskoppelen
		$(wrapperSelector).off('ds-crm-react-full-update', this.fullUpdate)
	}

	renderComplete(){
		if (this.state.debug)
			console.log('renderComplete - trigger ds-crm-web-component-geladen');

		// Trigger geven dat web component is geladen voor verdere initialisatie van bijv. custom selects
		$(document)[0].dispatchEvent(
			new Event('ds-crm-web-component-geladen', {
				bubbles: true,
				composed: true,
			}),
		);
	}

	updateListener(event) {
		if (this.state.debug)
			console.log('ds-crm-react-full-update - updatelistener - $.on(ds-crm-react-full-update)', $(wrapperSelector), event)

		// Event listener voor update triggers registreren
		$(wrapperSelector).on('ds-crm-react-full-update', this.fullUpdate)
	}

	fullUpdate(event){
		if (event.target.matches(wrapperSelector) && timestampLastUpdate !== event.timeStamp){
			if (this.state.debug)
				console.log('fullUpdate')

			this.restRequest();

			timestampLastUpdate = event.timeStamp;
		}
	}

	restRequest(){
		// Meldingen ophalen
		getMeldingen()
			.then(json => {
				this.setState({ meldingen: json });
			});
	}

	render(){
		if (process.env.NODE_ENV === "development") {
			return (
				<React.Fragment>
					<button id="refresh-alle-meldingen">REFRESH ALLES</button>
					<button className="melding-refresh"
						data-id={this.state.meldingen.posts ? this.state.meldingen.posts[0].ID : 'niet bekend'}
					>REFRESH 1E MELDING IN LIJST</button>
					<button className="melding-refresh"
						data-id={this.state.meldingen.posts ? this.state.meldingen.posts[1].ID : 'niet bekend'}
					>REFRESH 2E MELDING IN LIJST</button>

					<h1>Meldingen:</h1>

					<ul className="dropdown-menu">
						<div id="ds-crm-meldingen-dropdown">
							{this.state.meldingen.posts !== undefined && this.state.meldingen.posts.length > 0 && this.state.meldingen.posts.map((post) =>
								<MeldingenSingleMelding key={`MeldingenSingleMelding-${post.ID}-${Date.now()}`}
									post={post}
									template={this.state.template}
								/>
							)}
						</div>
					</ul>
				</React.Fragment>
			)
		} else {
			return (
				<ul className="dropdown-menu">
					<div id="ds-crm-meldingen-dropdown">
						{this.state.meldingen.posts !== undefined && this.state.meldingen.posts.length > 0 && this.state.meldingen.posts.map((post) =>
							<MeldingenSingleMelding key={`MeldingenSingleMelding-${post.ID}-${Date.now()}`}
								post={post}
								template={this.state.template}
							/>
						)}
					</div>
				</ul>
			)
		}
	}
}

class MeldingenDropdownComponent extends HTMLElement {
	// constructor(){
	// 	super();

	// 	// Do something more
	// }

	connectedCallback(){
		const attNames = this.getAttributeNames();
		var props = [];
		attNames.map((name) => {
			props[name] = this.getAttribute(name);
			return props;
		});

		// // Create a ShadowDOM
		// const root = this; //.attachShadow({ mode: 'open' });

		// // Create a mount element
		// const mountPoint = document.createElement('div');

		// // Place mount element
		// root.appendChild(mountPoint);

		// Render output inside mount element
		const rootMountPoint = ReactDOM.createRoot(this);
		rootMountPoint.render(
			// <React.StrictMode>
				<MeldingenDropdown {...props} />
			// </React.StrictMode>
		)
	}
}

customElements.define('meldingen-dropdown', MeldingenDropdownComponent);