import React from 'react';
import { getChecklistItem } from '../utils/query.js';
import $ from 'jquery';
import renderHTML from '../utils/renderHTML';

var timestampLastUpdate;

export default class SingleChecklistItem extends React.Component {
	state = {
		template: this.props.template,
		post: this.props.post,
		debug: false,
	}

	componentDidMount(){
		this.handleChange = this.handleChange.bind(this);
		this.changeListener()
	}

	componentDidUpdate(){
		// NOTE: lijkt niet nodig? Niet in voorbeeld op https://reactjs.org/docs/integrating-with-other-libraries.html
		// this.handleChange = this.handleChange.bind(this);
		this.changeListener()

		if (this.state.post)
			this.renderComplete();
	}

	componentWillUnmount(){
		if (this.state.post.id ){
			if (this.state.debug)
				console.log('componentWillUnmount - $.off(ds-crm-react-update)', $(`#taak-${this.state.post.id}`))

			$(`#taak-${this.state.post.id}`).off('ds-crm-react-update', this.handleChange)
		}
	}

	renderComplete(){
		if (this.state.debug)
			console.log('single item - renderComplete() - trigger ds-crm-web-component-geladen');

			// Event listener voor update triggers loskoppelen
		$(document)[0].dispatchEvent(
			new Event('ds-crm-web-component-geladen', {
				bubbles: true,
				composed: true,
			}),
		);
	}

	changeListener(event) {
		if (this.state.debug)
			console.log('ds-crm-react-update - changelistener - $.on(ds-crm-react-update)', $(`#taak-${this.state.post.id}`), event)

		// Event listener voor update triggers registreren
		$(`#taak-${this.state.post.id}`).on('ds-crm-react-update', this.handleChange)
	}

	handleChange(event) {
		if (event.target.matches(`#taak-${this.state.post.id}`) && timestampLastUpdate !== event.timeStamp){
			if (this.state.debug)
				console.log('single item - handleChange - update ophalen voor ID: ' + this.state.post.id, event)

			// Nieuwe data ophalen na change
			getChecklistItem( this.state.post.id )
				.then(json => {
					this.setState({ post: json });
				});

			timestampLastUpdate = event.timeStamp;
		}
	}

	render(){
		// In het sporadische geval dat de items sneller zijn geladen dan het template wachten op het template
		if (!this.state.template) return;

		var id = (this.state.post.id ? this.state.post.id : '');
		var id_encrypted = (this.state.post.id_encrypted ? this.state.post.id_encrypted : '');
		var titel = (this.state.post.title.rendered ? this.state.post.title.rendered : '');
		var edit_post_link = (this.state.post.edit_post_link ? this.state.post.edit_post_link : '');
		var bord_id = (this.state.post.bord_id ? this.state.post.bord_id : '');

		var metadata = this.state.post.metadata;
		var voltooid = (metadata && metadata.voltooid ? metadata.voltooid : '');
		var datum = (metadata && metadata.datum ? metadata.datum : '');
		var gekoppelde_gebruiker = (metadata && metadata['gekoppelde-gebruiker'] ? metadata['gekoppelde-gebruiker'] : '');

		var template = (voltooid !== undefined && voltooid ? this.state.template.voltooid : this.state.template.open );

		return renderHTML(
			template
				.replaceAll('${id}', id) // eslint-disable-line
				.replaceAll('${id-encrypted}', id_encrypted) // eslint-disable-line
				.replaceAll('${bord-id}', bord_id) // eslint-disable-line
				.replaceAll('${titel}', titel) // eslint-disable-line
				.replaceAll('${datum}', datum) // eslint-disable-line
				.replaceAll('${gekoppelde-gebruiker}', gekoppelde_gebruiker) // eslint-disable-line
				.replaceAll('${edit-post-link}', edit_post_link) // eslint-disable-line
		)
	}
}