import React from 'react';
// import { sanitize } from 'dompurify';
import HTMLString from 'react-html-string';

const renderHTML = rawHTML => {
	// var elTemp = React.createElement('div', {
	// 	dangerouslySetInnerHTML: {
	// 		__html: sanitize(rawHTML)
	// 	}
	// });
	// return elTemp;
	return <HTMLString html={rawHTML} />
}
export default renderHTML;
