import React, { Component } from "react";

export default class Svg extends Component {
	render(){
		return (
			<svg role="img"
				aria-labelledby="loading-aria"
				viewBox="0 0 100 20"
				preserveAspectRatio="none"
			>
				<title id="loading-aria">Loading...</title>
				<rect
					x="0"
					y="0"
					width="100%"
					height="100%"
					clipPath="url(#clip-path)"
					fill="url('#fill')"
				></rect>
				<defs>
					<clipPath id="clip-path">
						<rect x="0" y="0" rx="0" ry="0" width="100" height="20" />
					</clipPath>
					<linearGradient id="fill">
						<stop className="background"
							offset="0.599964"
							stopOpacity="1"
						>
							<animate attributeName="offset"
								values="-2; -2; 1"
								keyTimes="0; 0.25; 1"
								dur="2s"
								repeatCount="indefinite"
							></animate>
						</stop>
						<stop className="accentkleur"
							offset="1.59996"
							stopOpacity="1"
						>
							<animate attributeName="offset"
								values="-1; -1; 2"
								keyTimes="0; 0.25; 1"
								dur="2s"
								repeatCount="indefinite"
							></animate>
						</stop>
						<stop className="background"
							offset="2.59996"
							stopOpacity="1"
						>
							<animate attributeName="offset"
								values="0; 0; 3"
								keyTimes="0; 0.25; 1"
								dur="2s"
								repeatCount="indefinite"
							></animate>
						</stop>
					</linearGradient>
				</defs>
			</svg>
		)
	}
}